import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const { NODE_ENV: environment } = process.env;

export interface InitSentryConfig {
  dsn?: string;
}

/** Sentry should be initialized as soon as possible (ex. index.tsx) */
export const initSentry = (config: InitSentryConfig): void => {
  const { dsn } = config;

  if (environment !== 'development' && dsn) {
    // When not initialized, the Sentry SDK methods (ie. `Sentry.captureEvent`)
    // can be safely called but do not POST to the Sentry service.
    Sentry.init({
      environment,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.2,
      ...config
    });
  }
};
