import {
  Box,
  BoxProps,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
  makeStyles
} from '@material-ui/core';
import { differenceInCalendarDays, isPast } from 'date-fns';
import React from 'react';

import { Alert } from './Alert';

const secondaryActionWidth = 42;

const useStyles = makeStyles((theme) => ({
  paper: {
    overflow: 'hidden' // keep listed hover and selected color from leaking out of paper
  },
  itemWithSecondaryAction: {
    paddingRight: secondaryActionWidth + theme.spacing(4)
  },
  secondaryText: {
    marginTop: theme.spacing(1)
  },
  secondaryAction: {
    width: secondaryActionWidth,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pointerEvents: 'none',
    color: theme.palette.secondary.light
  }
}));

export interface AlertListProps extends Omit<BoxProps, 'onSelect'> {
  alerts: Alert[];
  selectedId?: string | null;
  onSelect?: (alertId: Alert['id']) => void;
}

const isExpired = (date: Date | null): boolean => {
  if (!date) return false;
  return isPast(date);
};

const expiresToday = (date: Date | null): boolean => {
  if (!date) return false;
  return differenceInCalendarDays(date, new Date()) === 0 && !isExpired(date);
};

const AlertList: React.FC<AlertListProps> = ({
  alerts,
  selectedId,
  onSelect,
  ...rootProps
}) => {
  const classes = useStyles();

  return (
    <Box mx={1} my={2} {...rootProps}>
      <Paper className={classes.paper}>
        <List disablePadding>
          {alerts.map((alert, index) => (
            <ListItem
              key={alert.id}
              classes={{ secondaryAction: classes.itemWithSecondaryAction }}
              button
              alignItems="flex-start"
              selected={selectedId === alert.id}
              divider={index < alerts.length - 1}
              onClick={() => onSelect && onSelect(alert.id)}
            >
              <ListItemText
                classes={{ secondary: classes.secondaryText }}
                primary={alert.message}
                primaryTypographyProps={{ style: { whiteSpace: 'pre-wrap' } }} // wrap new lines in text
                secondary={`Created ${alert.createdFormatted}  →  Expires ${alert.expirationFormatted}`}
                secondaryTypographyProps={{
                  variant: 'caption',
                  style: { whiteSpace: 'pre-wrap' } // ensure all explicit white space is rendered
                }}
              />
              {isExpired(alert.expiration) && (
                <ListItemSecondaryAction className={classes.secondaryAction}>
                  <Typography
                    variant="caption"
                    display="block"
                    align="center"
                    color="inherit"
                  >
                    Expired
                  </Typography>
                </ListItemSecondaryAction>
              )}
              {expiresToday(alert.expiration) && (
                <ListItemSecondaryAction className={classes.secondaryAction}>
                  <Typography
                    variant="caption"
                    display="block"
                    align="center"
                    color="inherit"
                  >
                    Expires Today
                  </Typography>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ))}
        </List>
      </Paper>
    </Box>
  );
};

export default AlertList;
