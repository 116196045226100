import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import {
  AmplifyAuthContainer,
  AmplifyAuthenticator,
  AmplifySignIn
} from '@aws-amplify/ui-react';
import { makeStyles } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import { logException } from '../services/logger';
import { useAmplifyTheme } from './useAmplifyTheme';

const useStyles = makeStyles((theme) => ({
  authenticator: {
    '--background-color': theme.palette.background.default,
    '--border-radius': `${theme.shape.borderRadius}px`,
    '--box-shadow': theme.shadows[2],
    '--padding': theme.spacing(1, 4, 4, 4)
  }
}));

export interface ComponentWithAuthenticationProps {
  isSignedIn?: boolean;
  onSignOut?: () => void;
}

const withAuthentication = <T extends ComponentWithAuthenticationProps>(
  Component: React.ComponentType<T>
): React.FC<T> => {
  const ComponentWithAuthentication: React.FC<T> = (props) => {
    const amplifyThemeClasses = useAmplifyTheme();
    const classes = useStyles();

    const [signedIn, setSignedIn] = useState<boolean>(false);

    useEffect(() => {
      return onAuthUIStateChange((authState, user) =>
        setSignedIn(!!(authState === AuthState.SignedIn && user))
      );
    });

    const signOut = async () => {
      try {
        await Auth.signOut();
      } catch (err) {
        logException(err);
      }
      setSignedIn(false);
    };

    return signedIn ? (
      <Component {...(props as T)} isSignedIn={signedIn} onSignOut={signOut} />
    ) : (
      <React.Fragment>
        <AmplifyAuthContainer>
          <AmplifyAuthenticator
            className={clsx(amplifyThemeClasses.root, classes.authenticator)}
            usernameAlias="email"
          >
            <AmplifySignIn
              slot="sign-in"
              headerText="P3Works Alert Manager"
              hideSignUp
            />
          </AmplifyAuthenticator>
        </AmplifyAuthContainer>
      </React.Fragment>
    );
  };

  return ComponentWithAuthentication;
};

export default withAuthentication;
