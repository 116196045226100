import '@fontsource/roboto';

import { CssBaseline, ThemeProvider } from '@material-ui/core';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { initAmplify } from './auth';
import reportWebVitals from './reportWebVitals';
import { initSentry } from './services/sentry';
import theme from './theme';

const {
  REACT_APP_SENTRY_DSN: sentryDSN,
  REACT_APP_AWS_REGION: awsRegion,
  REACT_APP_COGNITO_USERPOOLID: cognitoUserPoolId,
  REACT_APP_COGNITO_CLIENTID: cognitoClientId,
  REACT_APP_COGNITO_IDENTITY_ID: cognitoIdentityId
} = process.env;

initSentry({ dsn: sentryDSN });

initAmplify({
  region: awsRegion,
  userPoolId: cognitoUserPoolId,
  userPoolWebClientId: cognitoClientId,
  identityPoolId: cognitoIdentityId
});

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
