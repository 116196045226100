import { Box, makeStyles } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { endOfDay } from 'date-fns';
import React from 'react';

const useStyles = makeStyles({
  // @material-ui/pickers DatePicker has a max width of 325px.
  // We are using it in 375px wide containers. Mostly for an accurate representation of the P3Works preview.
  // 1. Increase max width to 375.
  // 2. When we increase the max width, the calendar is centered. Increase day size to account for larger area.
  // 3. Add addtional padding to bottom to make up for height lost with larger day buttons.
  // This will cause a smaller width calendar to have larger than expected days, but just use at 375 and no issues.
  // Tried https://material-ui-pickers.dev/guides/css-overrides, but had trouble with TS typings.
  // This is a simpler solution regardless since we are only using the picker in this component.
  root: {
    paddingBottom: 4,
    '& .MuiPickersBasePicker-pickerView': {
      maxWidth: 375
    },
    '& .MuiPickersCalendarHeader-dayLabel': {
      width: 42
    },
    '& .MuiPickersDay-day': {
      height: 42,
      width: 42
    }
  }
});

export interface ExpirationPickerProps {
  value: Date | null;
  onChange: (value: Date) => void;
}

const ExpirationPicker: React.FC<ExpirationPickerProps> = ({
  value,
  onChange
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <DatePicker
        value={value}
        autoOk
        disablePast
        disableToolbar
        variant="static"
        onChange={(date) => date && onChange(endOfDay(date))}
      />
    </Box>
  );
};

export default ExpirationPicker;
