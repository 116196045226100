import Amplify from 'aws-amplify';
import axios from 'axios';
import { format as formatDate } from 'date-fns';
import fileDownload from 'js-file-download';

import { logException } from '../services/logger';
import { Alert } from './Alert';

const {
  REACT_APP_ALERT_API_ENDPOINT: apiEndpoint = '',
  REACT_APP_ALERT_API_KEY: apiKey
} = process.env;

interface ServerAlert {
  id: string;
  message: string;
  created: number;
  expiration?: number;
}

const commonHeaders = async (): Promise<Record<string, unknown>> => {
  const session = await Amplify.Auth.currentSession();
  const { jwtToken } = session.idToken;
  return { 'x-api-key': apiKey, Authorization: `Bearer ${jwtToken}` };
};

/** Map alert as provided by the API to a local `Alert` interface */
const mapAlert = (serverAlert: ServerAlert): Alert => ({
  ...serverAlert,
  created: new Date(serverAlert.created),
  createdFormatted: formatDate(serverAlert.created, 'LL/dd/yyyy'),
  expiration: serverAlert.expiration ? new Date(serverAlert.expiration) : null,
  expirationFormatted: serverAlert.expiration
    ? `${formatDate(serverAlert.expiration, 'LL/dd/yyyy')}`
    : 'Not set'
});

const queryActive = async (): Promise<Alert[]> => {
  interface QueryServerResponse {
    alerts: ServerAlert[];
  }

  let data: QueryServerResponse;

  try {
    const headers = await commonHeaders();
    const response = await axios.get<QueryServerResponse>(apiEndpoint, {
      params: { active: true },
      headers
    });
    data = response.data;
  } catch (err) {
    logException(err);
    throw err;
  }

  const alerts = data.alerts.map(mapAlert);

  return alerts;
};

const downloadExpired = async (): Promise<void> => {
  let data: Blob;

  try {
    const headers = await commonHeaders();
    const response = await axios.get(apiEndpoint, {
      params: { active: false, format: 'csv' },
      headers,
      responseType: 'blob'
    });
    data = response.data;
  } catch (err) {
    logException(err);
    throw err;
  }

  fileDownload(data, 'P3Works Expired Alerts.csv');
};

const publish = async (createData: {
  id: string;
  message: string;
  expiration: Date;
}): Promise<Alert> => {
  interface CreateServerResponse {
    alert: ServerAlert;
  }

  let data: CreateServerResponse;

  try {
    const headers = await commonHeaders();
    const response = await axios.post<CreateServerResponse>(
      apiEndpoint,
      { ...createData, expiration: createData.expiration.valueOf() },
      { headers }
    );
    data = response.data;
  } catch (err) {
    logException(err);
    throw err;
  }

  const alert = mapAlert(data.alert);

  return alert;
};

const updateExpiration = async (
  alertId: string,
  expiration: Date
): Promise<Alert> => {
  interface ExpireServerResponse {
    alert: ServerAlert;
  }

  let data: ExpireServerResponse;

  try {
    const headers = await commonHeaders();
    const response = await axios.put<ExpireServerResponse>(
      `${apiEndpoint}/${alertId}/expiration`,
      { expiration: expiration.valueOf() },
      { headers }
    );
    data = response.data;
  } catch (err) {
    logException(err);
    throw err;
  }

  const alert = mapAlert(data.alert);

  return alert;
};

export { queryActive, downloadExpired, publish, updateExpiration, mapAlert };
