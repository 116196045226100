import Amplify from 'aws-amplify';

export interface ConfigureAmplifyProps {
  region?: string;
  userPoolId?: string;
  userPoolWebClientId?: string;
  identityPoolId?: string;
}

export const initAmplify = (config: ConfigureAmplifyProps): void => {
  Amplify.configure(config);
};
