import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Toolbar,
  Typography,
  makeStyles
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  title: {
    flexGrow: 1
  }
});

export interface AppBarProps extends MuiAppBarProps {
  title?: string;
  /** Actionable components at the right edge */
  SecondaryActions?: React.ReactNode;
}

const AppBar: React.FC<AppBarProps> = ({
  title = 'P3Works Alert Manager',
  SecondaryActions = null,
  ...rootProps
}) => {
  const classes = useStyles();

  return (
    <MuiAppBar position="fixed" color="secondary" elevation={1} {...rootProps}>
      <Toolbar variant="dense">
        <Typography variant="subtitle1" className={classes.title}>
          {title}
        </Typography>
        {SecondaryActions}
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
