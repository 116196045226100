import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Snackbar,
  Toolbar,
  makeStyles
} from '@material-ui/core';
import CloseDrawerIcon from '@material-ui/icons/CloseOutlined';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import PublishIcon from '@material-ui/icons/SmartphoneOutlined';
import { Alert as MuiAlert } from '@material-ui/lab';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import clsx from 'clsx';
import React, { useMemo, useState } from 'react';

import {
  Alert,
  AlertDetails,
  AlertList,
  PublishAlertDialog,
  downloadExpiredAlerts,
  useAlerts
} from './alerts';
import {
  ComponentWithAuthenticationProps,
  SignOutButton,
  withAuthentication
} from './auth';
import AppBar from './components/AppBar';

const drawerWidth = 375;

const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: drawerWidth
  },
  pageTitle: {
    flexGrow: 1
  },
  pageContent: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    height: 'calc(100vh - 48px)', // full height - appbar
    // Push content and scrollbars below the fixed App Bar.
    // https://material-ui.com/components/app-bar/#fixed-placement
    marginTop: 48, // height of dense toolbar
    marginRight: -drawerWidth,
    overflow: 'hidden auto' // content scrolls independently of page
  },
  pageContentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  },
  downloadButton: {
    fontSize: `${13 / 16}rem`,
    color: theme.palette.text.secondary
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    overflowX: 'hidden',
    background: theme.palette.background.default
  }
}));

const App: React.FC<ComponentWithAuthenticationProps> = ({
  isSignedIn,
  onSignOut
}) => {
  const classes = useStyles();

  // #region State
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);

  /** Setting to a new value resets the form values in the create dialog */
  const [createFormResetToken, setCreateFormResetToken] = useState<number>(
    new Date().valueOf()
  );

  const [snackbarContent, setSnackbarContent] =
    useState<React.ReactElement | null>(null);

  const [selectedAlertId, setSelectedAlertId] = useState<Alert['id'] | null>(
    null
  );

  const { alerts, updateExpiration, publish } = useAlerts();

  const selectedAlert = useMemo(() => {
    if (createModalOpen) return null; // unselect while working in modal
    if (!selectedAlertId) return null;
    return alerts.find((a) => a.id === selectedAlertId) || null;
  }, [alerts, createModalOpen, selectedAlertId]);

  const isDrawerOpen = !!selectedAlert;
  // #endregion

  // #region Create Modal Actions
  const openCreateModal = () => setCreateModalOpen(true);
  const closeCreateModal = () => setCreateModalOpen(false);
  // #endregion

  // #region Alert Actions
  const publishAlert = async (data: { message: string; expiration: Date }) => {
    let published: Alert;

    try {
      published = await publish(data);
    } catch (_err) {
      setSnackbarContent(
        <MuiAlert severity="error">Unable to send. Alert not created.</MuiAlert>
      );
      return;
    }

    // NOTE.
    // These 3 state changes will trigger 3 renders (inside async).
    // Prob no big deal but may want to look into a cleaner state update.
    // Maybe combine modal open and form reset into a single state.
    // Concurrent mode would also result in just a single render. https://reactjs.org/docs/concurrent-mode-intro.html
    setSelectedAlertId(published.id); // Select newly created
    setCreateModalOpen(false);
    setCreateFormResetToken(new Date().valueOf()); // Reset form values
  };

  const updateAlertExpiration = async (
    alertId: string,
    expiration: Date
  ): Promise<void> => {
    try {
      await updateExpiration(alertId, expiration);
    } catch (_err) {
      setSnackbarContent(
        <MuiAlert severity="error">
          Unable to update expiration. No changes were made.
        </MuiAlert>
      );
    }
  };
  // #endregion

  return (
    <Box display="flex">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <React.Fragment>
          <AppBar
            className={clsx(classes.appBar, {
              [classes.appBarShift]: isDrawerOpen
            })}
            SecondaryActions={
              isSignedIn ? (
                <SignOutButton
                  color="inherit"
                  onSignOut={onSignOut || (() => undefined)}
                />
              ) : null
            }
          />
          <Box
            component="main"
            className={clsx(classes.pageContent, {
              [classes.pageContentShift]: isDrawerOpen
            })}
          >
            <Box px={2} my={2}>
              <Button
                variant="text"
                color="primary"
                startIcon={<PublishIcon />}
                onClick={openCreateModal}
              >
                Send New Alert
              </Button>
            </Box>
            <AlertList
              alerts={alerts}
              selectedId={selectedAlert?.id || null}
              onSelect={setSelectedAlertId}
            />
            <Divider />
            <Box my={2} px={2}>
              <Button
                className={classes.downloadButton}
                startIcon={<DownloadIcon />}
                onClick={downloadExpiredAlerts}
              >
                Expired Alerts (CSV)
              </Button>
            </Box>
          </Box>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={isDrawerOpen}
            classes={{ paper: classes.drawerPaper }}
            onClose={() => setSelectedAlertId(null)}
          >
            <Toolbar variant="dense" disableGutters>
              <IconButton onClick={() => setSelectedAlertId(null)}>
                <CloseDrawerIcon fontSize="small" />
              </IconButton>
            </Toolbar>
            {selectedAlert && (
              <AlertDetails
                alert={selectedAlert}
                onExpirationChange={(expiration) =>
                  updateAlertExpiration(selectedAlert.id, expiration)
                }
              />
            )}
          </Drawer>
          <PublishAlertDialog
            open={createModalOpen}
            resetToken={createFormResetToken}
            onClose={closeCreateModal}
            onSubmit={publishAlert}
          />
          <Snackbar
            open={!!snackbarContent}
            autoHideDuration={6000}
            onClose={() => setSnackbarContent(null)}
          >
            {snackbarContent || undefined}
          </Snackbar>
        </React.Fragment>
      </MuiPickersUtilsProvider>
    </Box>
  );
};

export default withAuthentication(App);
