import { makeStyles } from '@material-ui/core';

export const useAmplifyTheme = makeStyles((theme) => ({
  root: {
    '--amplify-font-family': theme.typography.body1.fontFamily,
    '--amplify-text-xxs': theme.typography.caption.fontSize,
    '--amplify-text-xs': theme.typography.caption.fontSize,
    '--amplify-text-sm': theme.typography.body1.fontSize,
    '--amplify-text-md': theme.typography.body1.fontSize,
    '--amplify-text-md-sub': theme.typography.h5.fontSize,
    '--amplify-text-lg': theme.typography.h5.fontSize,
    '--amplify-text-xl': theme.typography.h5.fontSize,
    '--amplify-text-xxl': theme.typography.h5.fontSize,
    '--amplify-primary-color': theme.palette.primary.main,
    '--amplify-primary-contrast': theme.palette.primary.contrastText,
    '--amplify-primary-tint': theme.palette.primary.light,
    '--amplify-primary-shade': theme.palette.primary.dark,
    '--amplify-secondary-color': theme.palette.text.primary, // used for headers and input labels
    '--amplify-secondary-contrast': theme.palette.secondary.contrastText,
    '--amplify-secondary-tint': theme.palette.grey[500], // used for error alert background
    '--amplify-secondary-shade': theme.palette.secondary.dark,
    '--amplify-background-color': theme.palette.background.paper,
    '--amplify-white': theme.palette.common.white
  }
}));
