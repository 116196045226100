import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Alert } from './Alert';
import {
  mapAlert,
  publish as servicePublish,
  queryActive as serviceQueryActive,
  updateExpiration as serviceUpdateExpiration
} from './alertAPIService';

interface UseAlertsResult {
  alerts: Alert[];
  publish: (data: { message: string; expiration: Date }) => Promise<Alert>;
  updateExpiration: (alertId: string, expiration: Date) => Promise<Alert>;
}

export const useAlerts = (): UseAlertsResult => {
  const [alerts, setAlerts] = useState<Alert[]>([]);

  // Load alerts state from query when component mounts
  useEffect(() => {
    const query = async () => {
      const queriedAlerts = await serviceQueryActive();
      setAlerts(queriedAlerts);
    };

    query();
  }, []);

  /**
   * Publish new alert.
   * UI is updated optimistically. Alert added to list immediately, then API is called.
   * Changed is rolled back in UI if API call fails.
   */
  const publish: UseAlertsResult['publish'] = async ({
    message,
    expiration
  }) => {
    const id = uuidv4();

    const alertsCache = [...alerts];

    setAlerts((alerts) => [
      // Alerts are sorted with newest at the top of the list (created descending)
      // Add new alert at start of array
      mapAlert({
        id,
        message,
        created: new Date().valueOf(),
        expiration: expiration.valueOf()
      }),
      ...alerts
    ]);

    let alert: Alert;

    try {
      alert = await servicePublish({ id, message, expiration });
    } catch (err) {
      setAlerts(alertsCache); // roll back optimistic update
      throw err;
    }

    return alert;
  };

  /**
   * Set alert expiration.
   * UI is updated optimistically. Expiration is applied immediately, then API is called.
   * Changed is rolled back in UI if API call fails.
   */
  const updateExpiration: UseAlertsResult['updateExpiration'] = async (
    alertId,
    expiration
  ) => {
    const alertsCache = [...alerts];

    setAlerts((alerts) =>
      alerts.map((n) =>
        n.id === alertId
          ? mapAlert({
              ...n,
              created: n.created.valueOf(),
              expiration: expiration.valueOf()
            })
          : n
      )
    );

    let alert: Alert;

    try {
      alert = await serviceUpdateExpiration(alertId, expiration);
    } catch (err) {
      setAlerts(alertsCache); // roll back optimistic update
      throw err;
    }

    return alert;
  };

  return {
    alerts,
    publish,
    updateExpiration
  };
};
