import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { Alert as MuiAlert } from '@material-ui/lab';
import { endOfDay } from 'date-fns';
import React, { useEffect, useState } from 'react';

import ExpirationPicker from './ExpirationPicker';
import PostIcon from './SendToMobileIcon';

const defaultMessage = '';
const defaultExpiration = endOfDay(new Date());
const messageLimit = 256; // character limit enforced by API

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    maxWidth: 375 + theme.spacing(1) * 2
  },
  alert: {
    borderRadius: 0
  },
  helperText: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

export interface PublishAlertDialogProps {
  open: boolean;
  /**
   * Provide unique number (like `new Date().valueOf()`) to reset form values.
   * Can be used to reset the form after a successful submission.
   */
  resetToken?: number;
  onSubmit: (data: { message: string; expiration: Date }) => void;
  onClose?: () => void;
}

const PublishAlertDialog: React.FC<PublishAlertDialogProps> = ({
  open,
  resetToken,
  onSubmit,
  onClose
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [message, setMessage] = useState<string>(defaultMessage);
  const [expiration, setExpiration] = useState<Date>(defaultExpiration);

  const messageLimitExceeded = message.length > messageLimit;
  const submitDisabled =
    submitting || messageLimitExceeded || message.length === 0;

  useEffect(() => {
    setMessage(defaultMessage);
    setExpiration(defaultExpiration);
    setSubmitting(false);
  }, [resetToken]);

  const submit = async () => {
    setSubmitting(true);
    onSubmit({ message, expiration });
    // `submitting` should be reset to false after async submit completes.
    // That information is not available here.
    // Reset after 1 sec on the assumption the submit has completed.
    // The primary reason for setting `submitting` is to disable button and prevent multiple submits.
    setTimeout(() => setSubmitting(false), 1000);
  };

  // Some negative x-axis margins used to move elements into dialog padding (default 3 spacings - 24px).
  // If default dialog padding changes, these will need to be adjusted.
  // Dividers (-3) - full width to edge of dialog.
  // Expiration picker (-2) - offset internal 2 spacings padding.
  return (
    <Dialog
      open={open}
      classes={{ paper: classes.dialogPaper }}
      fullScreen={fullScreen}
    >
      <DialogTitle>Publish New Alert</DialogTitle>
      <Divider />
      <DialogContent>
        <Box mt={-1} mb={2} mx={-3}>
          <MuiAlert className={classes.alert} severity="info">
            P3Works users will immediately receive a push notification.
          </MuiAlert>
        </Box>
        <TextField
          id="message"
          variant="standard"
          label="Message content"
          value={message}
          onChange={(evt) => setMessage(evt.target.value)}
          margin="dense"
          multiline
          rows={6}
          error={messageLimitExceeded}
          FormHelperTextProps={{ className: classes.helperText }}
          helperText={
            <React.Fragment>
              <Typography variant="caption">
                {messageLimitExceeded ? 'Maximum length exceeded' : ''}
              </Typography>
              <Typography variant="caption">
                {message.length}/{messageLimit}
              </Typography>
            </React.Fragment>
          }
          autoFocus
          fullWidth
        />
        <Box my={3} mx={-3}>
          <Divider light />
        </Box>
        <DialogContentText>
          Expire alert at midnight of this day
        </DialogContentText>
        <Box mx={-2}>
          <ExpirationPicker value={expiration} onChange={setExpiration} />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box px={1}>
          <Button size="large" onClick={() => onClose && onClose()}>
            Cancel
          </Button>
          <Button
            size="large"
            color="primary"
            endIcon={
              submitting ? (
                <CircularProgress color="inherit" size={22} />
              ) : (
                <PostIcon />
              )
            }
            disabled={submitDisabled}
            onClick={submit}
          >
            Send
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default PublishAlertDialog;
