import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

// Icon is from the Material Design icon set but not available in material-ui icons
// https://fonts.google.com/icons?selected=Material%20Icons%20Outlined%3Asend_to_mobile

const SendToMobileIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M18,8l4,4l-4,4l-1.41-1.41L18.17,13H13v-2h5.17l-1.59-1.59L18,8z M7,1.01L17,1c1.1,0,2,0.9,2,2v4h-2V6H7v12h10v-1h2v4 c0,1.1-0.9,2-2,2H7c-1.1,0-2-0.9-2-2V3C5,1.9,5.9,1.01,7,1.01z M7,21h10v-1H7V21z M7,4h10V3H7V4z" />
  </SvgIcon>
);

export default SendToMobileIcon;
