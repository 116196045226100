import { alpha, createTheme } from '@material-ui/core';
import { pink } from '@material-ui/core/colors';

/** Precinct 3 red */
const primary = {
  main: '#87000d',
  light: '#be3f34',
  dark: '#540000',
  contrastText: '#ffffff'
};

/** Precinct 3 blue */
const secondary = {
  main: '#1e2f4f',
  light: '#7785aa',
  dark: '#000527',
  contrastText: '#ffffff'
};

const theme = createTheme({
  palette: {
    primary,
    secondary,
    error: {
      main: pink[500]
    },
    action: {
      hover: alpha(secondary.main, 0.04),
      hoverOpacity: 0.04,
      selected: alpha(secondary.main, 0.08),
      selectedOpacity: 0.08
    }
  },
  typography: {
    htmlFontSize: 16,
    fontSize: 14,
    h5: {
      fontSize: `${20 / 16}rem`,
      fontWeight: 400,
      lineHeight: 24 / 20
    },
    h6: {
      fontSize: `${18 / 16}rem`,
      fontWeight: 400,
      lineHeight: 24 / 18
    },
    subtitle1: {
      fontSize: `${16 / 16}rem`,
      lineHeight: 20 / 16
    },
    subtitle2: {
      fontSize: `${14 / 16}rem`,
      fontWeight: 500,
      lineHeight: 20 / 14
    },
    body1: {
      fontSize: `${14 / 16}rem`,
      lineHeight: 20 / 14
    },
    caption: {
      fontSize: `${12 / 16}rem`,
      lineHeight: 18 / 12
    },
    button: {
      fontSize: `${14 / 16}rem`,
      fontWeight: 400,
      lineHeight: 20 / 14
    }
  },
  overrides: {
    MuiButton: {
      text: {
        padding: '8px 11px'
      }
    },
    MuiFormHelperText: {
      root: {
        // caption typography
        fontSize: `${13 / 16}rem`,
        lineHeight: 20 / 13
      },
      contained: {
        marginLeft: 0, // align helper text to left edge of input (inset by default)
        marginRight: 0 // align helper text to right edge of input (inset by default)
      }
    },
    MuiInputBase: {
      root: {
        lineHeight: 20 / 14 // multi-line textarea dense line-height without (expiration message)
      }
    }
  }
});

export default theme;
