import { Button, ButtonProps } from '@material-ui/core';
import React from 'react';

interface SignOutButtonProps extends ButtonProps {
  onSignOut: () => void;
}

const SignOutButton: React.FC<SignOutButtonProps> = ({
  onSignOut,
  ...buttonProps
}) => (
  <Button size="small" {...buttonProps} onClick={() => onSignOut()}>
    Sign Out
  </Button>
);

export default SignOutButton;
