import * as Sentry from '@sentry/react';

/**
 * Log a manually created event. Events are logged to the Sentry service and to the console.
 * @param message Message describing the event
 * @param data Additional data to better provide context for the event
 */
const logEvent = (message: string, data?: Record<string, unknown>): void => {
  const sentryId = Sentry.captureEvent({ message, extra: data });
  // eslint-disable-next-line no-console
  console.log(message, data, `Sentry ID: ${sentryId}`);
};

/**
 * Log an application exception. Exceptions are logged to the Sentry service and to the console.
 * @param exception JavaScript `Error` to log
 */
const logException = (exception: Error): void => {
  const sentryId = Sentry.captureException(exception);
  // eslint-disable-next-line no-console
  console.error(exception, `Sentry ID: ${sentryId}`);
};

export { logEvent, logException };
