import {
  Box,
  BoxProps,
  Button,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { formatDistanceToNow } from 'date-fns';
import React from 'react';

import { Alert } from './Alert';
import ExpirationPicker from './ExpirationPicker';

const { PUBLIC_URL: publicURL } = process.env;

/**
 * Single "section". Sections have a paper background with dividers above and below.
 * Sections are separated by empty space with default background.
 * Sections have an optional label placed at the top and outside the paper background.
 */
const DetailsSection: React.FC<{ label?: string }> = ({ label, children }) => (
  <Box mt={2} mb={3}>
    {!!label && (
      <Box px={2} mb={1}>
        <Typography variant="caption" color="textSecondary">
          {label}
        </Typography>
      </Box>
    )}
    <Divider />
    <Box bgcolor="background.paper">{children}</Box>
    <Divider />
  </Box>
);

const useStyles = makeStyles((theme) => ({
  relativeExpiration: {
    marginLeft: theme.spacing(1)
  },
  // Not using app theme. Colors should match P3Works. Could create a P3Works theme for use here though.
  previewSurface: {
    backgroundColor: '#cc003d', // P3Works secondary
    color: '#ffffff'
  },
  previewMessage: {
    letterSpacing: '0.25px',
    whiteSpace: 'pre-wrap'
  },
  previewCloseIcon: {
    fontSize: '18px',
    opacity: 0.64
  },
  previewHomepageImage: {
    width: '100%'
  }
}));

export interface AlertDetailsProps extends BoxProps {
  alert: Alert;
  onExpirationChange: (expiration: Date) => void;
}

const AlertDetails: React.FC<AlertDetailsProps> = ({
  alert,
  onExpirationChange,
  ...rootProps
}) => {
  const classes = useStyles();

  return (
    <Box {...rootProps}>
      <DetailsSection label="Expiration">
        <Box px={2} pt={2}>
          <Typography paragraph>
            {alert.expirationFormatted}
            {!!alert.expiration && (
              <Typography
                className={classes.relativeExpiration}
                component="span"
                variant="caption"
                color="textSecondary"
              >
                (
                {formatDistanceToNow(alert.expiration, {
                  addSuffix: true
                })}
                )
              </Typography>
            )}
          </Typography>
        </Box>
        <Divider light />
        <ExpirationPicker
          value={alert.expiration}
          onChange={onExpirationChange}
        />
        <Box px={2} pb={2}>
          <Button
            color="primary"
            fullWidth
            onClick={() => onExpirationChange(new Date())}
          >
            Expire Alert Now
          </Button>
        </Box>
      </DetailsSection>

      <DetailsSection label="P3Works Preview">
        <Box
          className={classes.previewSurface}
          p={2}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Typography className={classes.previewMessage} variant="body2">
            {alert.message}
          </Typography>
          <Box pt="2px" mr="-3px" pl="12px">
            <CloseIcon className={classes.previewCloseIcon} color="inherit" />
          </Box>
        </Box>
        <img
          className={classes.previewHomepageImage}
          src={`${publicURL}/p3works_home.png`}
          alt="P3Works homepage"
        />
      </DetailsSection>
    </Box>
  );
};

export default AlertDetails;
